import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BirthdayContainer, PeopleContainer, IconContainer, Button, MainContainer } from './styles';
import { findMonth } from '../../utils/methods';
import { getBirthdaysList } from '../../redux/actions';
import { isNull, isUndefined} from 'lodash';
import {LoaderContainer} from "../Content/styles";
import Loading from '../Loading';
import { withTranslation } from 'react-i18next';

class BirthdayCard extends React.Component {

  componentDidMount(){
    const { getBirthdaysList } = this.props;
    getBirthdaysList();
  }
  navigate = () => {
    localStorage.setItem('showBirthdaySidebar', 1);
    this.props.history.push('/social');
  }
  addDefaultSrc = (e) => {
    e.target.src='public/images/fallback.png';
  }
  render() {
    const {birthdayList, callBack, t } = this.props;
    const date = new Date();
    const month =  findMonth(date.getMonth());
    return(
      <MainContainer margin={this.props.dashboard?"25px": "25px"}>
        { isNull(birthdayList) || isUndefined(birthdayList)
          ?
          <LoaderContainer><Loading/></LoaderContainer>
          :
          <BirthdayContainer>
            <div>
              <div>{t(month)}</div>
              <div>{t('Birthdays')}!</div>
            </div>
            <div>
              <PeopleContainer>
                <IconContainer>
                  <span className="circle">{birthdayList && birthdayList.length > 0 && birthdayList.length}+</span>
                </IconContainer>
                <div><span>{t(`People's Birthdays`)}</span></div>
              </PeopleContainer>
              <Button onClick={() => {this.props.dashboard ? this.navigate() : callBack()}}>
                {t("Wish them")}!
              </Button>
            </div>
          </BirthdayContainer>
        }
      </MainContainer>
    );
  }
}
BirthdayCard.propTypes = {
  birthdayList: PropTypes.array,
  getBirthdaysList: PropTypes.func,
  callBack: PropTypes.func,
  dashboard: PropTypes.number,
  history: PropTypes.object,
  t: PropTypes.func,
};
const mapStateToProps = (state) => ({
  birthdayList: state.profileData.birthdayList,
})
const mapDispatchToProps = (dispatch) => ({
  getBirthdaysList: () => dispatch(getBirthdaysList())
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BirthdayCard));
