import styled from 'styled-components';
// import {StyledTabs} from '../CompanyDashBoardV2/styles';
// const BoldFont = 'Rubik-Medium';
import { Modal } from 'react-bootstrap';
// const MediumFont = 'Rubik-Regular';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: auto;
    margin: unset;
    position: unset;
  }
`

const NotificationWrapper =  styled.div`
position: absolute;
${'' /* top: -70px; */}
border-radius: 6px;
z-index: 1999;
box-shadow: 1px 1.732px 12px rgb(0 0 0 / 18%);
background-color: #fff;
width: 375px;
right:0px;
> div.birthdayContainer {
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  height: 100vh;
  ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
  }
}
.noBirthday{
    padding: 20px;
    display: flex;
    justify-content: center;
}

.noAnniversary{
  padding: 20px;
  display: flex;
  justify-content: center;
}

  .read{
    background-color: #fff; 
  }

  @media (max-width: 350px) {
    width: 100%;
  }
`;
const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 80px;
    height: 15%;
    background: linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
    padding: 35px 0px 0px 0px;
    color: #FFFFFF;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 24px;
    position: relative;
    .backarrow{
      width: 100%;
      margin: 5px 0;
      position: absolute;
      top: 5px;
      right: -15px;
      cursor: pointer;
      >img{
        transform: rotateY(180deg);
        width: 20px;
        height: 20px;
      }
    }
`;

const TabName = styled.div`
    border-bottom: ${({ active }) => active ? '4px solid #0D4270' : 'none'};
    cursor : pointer;
    width: ${({index}) => index===0 ? "30%" : "50%"};
    padding: 10px;
    font-size: 18px;
    text-align: center;
`;

const Banner = styled.div`
    position: relative;
    padding: 15px;
    .currentMonth{
      position: absolute;
      top: ${({show}) => show ? `27%` : `25%` };
      color: ${({show}) => show ? `saddlebrown` : `blue` };
      text-transform: uppercase;
      left: 43%;
      font-size: 20px;
    }
    >img{
      height: auto;
      width: 344px;
      border-radius: 6px;
    }
`

const Image = styled.div`
  width:15%;
  padding:10px;
`

const TodaySection = styled.div``

const Top = styled.div`
    padding: 5px 0 5px 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* text-align: center; */
    color: #0D4270;
    background: rgba(156, 156, 156, 0.1);
`

// const Content = styled.div``

const Card = styled.div``

const Container = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
`;

const LeftSection = styled.div`
  width: 70%;
  display: flex;
`;

const RightSection = styled.div`
  display: flex;
  min-width: 90px;
  height: 40px;
  background: ${({status})=> (status===3 || status===0) && (status!==5 || status!==4) ? '#FD7175' : 'none' };
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  border: ${({status})=> (status===3 || status===0) && (status!==5 || status!==4) ? 'none' : '1px solid #0D4270' };
  cursor: pointer;
`;

const Img = styled.div`
  width: 25%;
  display: flex;
    align-items: center;
  >img{
    width: 45px;
    height: 45px;
    border-radius: 3px;
  }
`;

const Content = styled.div`
  padding: 5px 0;
  width: 75%;
  >div:first-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
  >div:last-child{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9c9c9c;
    margin-top: 10px;
  }
`;

const Button = styled.div`
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #0D4270;
`;

const UpcomingBirthday = styled.div`
    padding: 5px 0 5px 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* text-align: center; */
    color: #0D4270;
    background: rgba(156, 156, 156, 0.1);
`;

const Recent = styled.div`
    padding: 5px 0 5px 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* text-align: center; */
    color: #0D4270;
    background: rgba(156, 156, 156, 0.1);   
`

const UpcomingSection = styled.div``

const RecentSection = styled.div``

const Right = styled.div`
    display: flex;
    justify-content: space-around;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ScrollStyles = styled.div`
overflow-x: hidden;
overflow-y: auto;
overflow: -moz-scrollbars-none;
::-webkit-scrollbar {
 width: 5px;
 height: 5px;
}

::-webkit-scrollbar-thumb {
 background: #c7c8c7;
 cursor: pointer;
 border-radius: 2.5px;
}

::-webkit-scrollbar-track {
 background: #fff;
 border-radius: 2.5px;
}
`;

const BannerNew = styled.div`
    position: relative;
    padding: 15px;
    .currentMonth{
      position: absolute;
      top: ${({show}) => show ? `27%` : `25%` };
      color: ${({show}) => show ? `saddlebrown` : `blue` };
      text-transform: uppercase;
      left: 44%;
      font-size: 20px;
    }
    >img{
      height: auto;
      width: 344px;
      border-radius: 6px;
    }
    `;
export {
  NotificationWrapper, TitleContainer, TabName, Banner, Image, TodaySection, Top, Content, Card, Container, LeftSection, Img,
  RightSection, Button, UpcomingBirthday, Recent , UpcomingSection, RecentSection, Right, StyledModal, ScrollStyles, BannerNew
}
